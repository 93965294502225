.grey-background-color {
    background-color: rgb(247, 247, 247);
    /* min-height: 100%; */
    height: auto !important;
    /* height: 90vh; */ 
    /* use the height: 90vh if not using the picture */
}

.right-border {
    border-right: 1px solid #d2d2d2;
}

.contact-info-label {
    color: black;
    font-size: 38px;
}

.contact-info-label-mobile {
    color: black;
    font-size: 38px;
}

.contact-info-abastas-cabinets-label {
    color: black;
    font-size: 20px;
}

.bottom-abastas-cabinets-label {
    color: black;
    font-size: 25px;
    font-weight: bold;
}

.contact-us-section-text-align-left {
    text-align: left;
    padding-left: 50px;
}

.send-a-message-section-text-align-left {
    text-align: left;
}

.contact-us-section-headers {
    font-size: 30px;
}

.contact-us-section-headers-mobile {
    text-align: center;
    font-size: 30px;
}

.phone {
    color: black;
}

.phone-mobile {
    color: black;
    margin-top: 3px;
}

.image-link-styling-and-spacing {
    /* margin-left: 5px; */
    color: black;
}

.image-link-styling-and-spacing-mobile {
    /* margin-left: 5px; */
    color: black;
    font-size: 15px;
}

.instagram {
    /* color: rgb(250, 53, 135); */
    color: black;
}

.instagram-mobile {
    color: black;
    margin-top: 3px;
}

.facebook {
    color: black;
    border: 7%;
}

.facebook-mobile {
    color: black;
    margin-top: 3px;
}

.send-a-message-label {
    color: black;
    font-size: 38px;
}

.email-input {
    background-color: white;
    color: black;
    border-color: #d2d2d2;
}

.message-input {
    background-color: white;
    color: black;
    border-color: #d2d2d2;
}

.button {
    background-color: black;
    border-color: black;
    font-size: 1em;
    font-weight: bold;
    width: auto;
    margin: 0 16px 0 0;
    border-radius: 20px;
    padding-left: 20px;
    padding-right:20px;
}

.map-image-size {
    width: 100%;
    height: 100%;
}

.contact-us-image-styling {
    margin-left: 5px !important;
}





hr {
    display: block;
    height: 20px;
    color: white;
    border-top: 1px solid white;

}

.textarea.form-control.message-box {
    height: 190px !important;
}





a {
    color: inherit;
}





.footer {
    height: 5%;
    color: black;
}


.col-padding {
    padding-top: 0%;
    padding-left: 3%;
    padding-right: 3%;
}



.brown-background-color {
    background-color: #b38a4c;
}