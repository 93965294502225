.parallax-image-background {
    background-color: rgb(247, 247, 247);
}

.about-us-background {
    background-color: #b38a4c;
}

.portfolio-image-background {
    background-color: rgb(247, 247, 247);
}