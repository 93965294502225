.portfolio-text-color {
    color: #b38a4c;
}

.portfolio-text-size {
    font-size: 60px;
}

.portfolio-text-size-mobile {
    font-size: 60px;
}

/* .carousel-custom-container-class {
    height: 80%;
    width: 80%;
} */

/* this is the css property to actually size the pictures. looks like this is the
    only place where the height property is recognized */
.carousel-custom-item-class {
    height: 50%;
    width: 50%;
}

.portfolio-header-web-margins {
    margin-bottom: 5px;
}

.portfolio-header-margins {
    /* margin-top: 20px; */
    margin-bottom: 10px;
}

.carousel-bottom-margin {
    margin-bottom: 10px;
}