.about-us-text {
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
}

.about-us-text-mobile {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
}

.about-us-text-color {
    color: white;
}