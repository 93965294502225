.navigation-top-margin {
    margin-top: 20px;
}

.left-text-align {
    text-align: left;
    margin-left: 100px;
}

.right-text-align {
    text-align: right;
    margin-right: 100px;
}

.header-supporting-text {
    justify-content: center;
    margin-top: -10px;
}

.nav-links-spacing {
    padding-left: 10px;
}

a {
    color: inherit;
}