.left-background-color {
    background-color: rgb(247, 247, 247);
    /* margin-bottom: 35px; */
}

.left-service-text-color {
    color: #b38a4c;
}

.right-background-color {
    background-color: #b38a4c;
}

.right-service-text-color {
    color: white;
}

.service-description-text {
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
    font-size: 18px;
}

.responsive {
    width: 100%;
    height: auto;
}

/* .negative-top-spacing {
    margin-top: -200px;
} */

.service-text-spacing {
    margin-top: 250px;
    /* margin-top: 20%; */
}


@media (min-width: 1441px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 800px;
    }
}

@media (max-width: 1440px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 700px;
    }
}


@media (max-width: 1370px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 700px;
    }
}

@media (max-width: 1300px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 650px;
    }
}

@media (max-width: 1000px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 600px;
    }
}

@media (max-width: 970px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 550px;
    }
}

@media (max-width: 776px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 600px;
    }
}

@media (max-width: 680px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 500px;
    }
}

@media (max-width: 570px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 450px;
    }
}

@media (max-width: 400px) {
    /* this is the one to shrink the size of the whole carousel */
    .container-class-custom {
        height: 350px;
    }
}

/* this is for the image itself */
.item-class-custom {
    height: 60%;
    width: 60%;
}

.align-row-content {
    display: flex;
    align-items: center;
}

.service-description-text-mobile {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.mobile-header-spacing {
    margin-top: 10px;
}